import type { FC, ReactNode } from 'react';

import useAuth from '@zen/Auth/hooks/useAuth';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Avatar from '@zen/Components/Avatar';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import type { ContextMenuState, MenuItemType } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import { accountSettingsRoutes, settingsRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';

const UserMenu: FC = () => {
  const { check } = useGlobalPermissions();
  const { logout } = useAuth();
  const { userProfile } = useAccount();
  const canViewAccountSettings: boolean = check('accounts.canViewAccountSettings');

  const { firstName, lastName } = userProfile;

  const classNames: string =
    'transition-all duration-200 ease-linear flex items-center h-16 mb-px cursor-pointer px-6 text-sm text-navy-base hover:bg-grey-lightest';

  const horizontalMenuOffset: number = 5;
  const verticalMenuOffset: number = 5;

  const handleLogout = async (): Promise<void> => {
    await logout();
  };

  const items: MenuItemType[] = [
    {
      icon: 'zicon-profile',
      label: 'Profile settings',
      linkTo: settingsRoutes.profileSettings.getUrl()
    },
    {
      addDivider: true,
      icon: 'zicon-bell',
      label: 'Notification settings',
      linkTo: settingsRoutes.notifications.getUrl()
    },
    ...(canViewAccountSettings
      ? [
          {
            addDivider: true,
            icon: 'zicon-office' as const,
            label: 'Account settings',
            linkTo: accountSettingsRoutes.index.getUrl()
          }
        ]
      : []),
    {
      icon: 'zicon-logout',
      label: 'Log out',
      onClick: handleLogout
    }
  ];

  return (
    <div data-testid="user-menu">
      <ContextMenu items={items} menuOffset={[horizontalMenuOffset, verticalMenuOffset]}>
        {({ isExpanded }: ContextMenuState): ReactNode => (
          <div className={classNames}>
            <Avatar className="mr-3" firstName={firstName} />
            <div className="pr-1 truncate w-28">
              {firstName} {lastName.charAt(0)}.
            </div>
            <ChevronIcon className="text-lg" expanded={isExpanded} size="small" />
          </div>
        )}
      </ContextMenu>
    </div>
  );
};

export default UserMenu;
