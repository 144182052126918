import { merge } from 'lodash';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountId } from '@zen/App/AccountIdContext';
import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import { SplashScreen } from '@zen/DesignSystem';
import { authRoutes } from '@zen/Routes';
import type { Optional } from '@zen/utils/typescript';

import { AccountPermissionsContextProvider } from './AccountPermissionsContext';
import { GlobalPermissionsContextProvider } from './GlobalPermissionsContext';
import { useAuthorizationQuery } from './graphql';
import { Role } from './role';
import RoleContext from './RoleContext';
import type { Authorization } from './types';

const getRole = (data: Optional<Authorization>): Role => {
  let role = Role.CUSTOMER_USER;

  if (!data) {
    return role;
  }

  const { agentForwarderAccounts, manufacturerAccounts, customerUserAccounts, isAdmin } = data;

  if (agentForwarderAccounts.length > 0) {
    role = Role.AGENT_FORWARDER;
  }

  if (manufacturerAccounts.length > 0) {
    role = Role.MANUFACTURER;
  }

  if (customerUserAccounts.length > 0) {
    role = Role.CUSTOMER_USER;
  }

  if (isAdmin) {
    role = Role.ADMIN;
  }

  return role;
};

const RoleProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { accountId } = useAccountId();

  const {
    data,
    loading: isLoading,
    error
  } = useAuthorizationQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    variables: {
      accountId: accountId || ''
    }
  });
  const navigate = useNavigate();
  const authorization: Optional<Authorization> = data?.authorization;

  useEffect(() => {
    const storageListener = () => {
      if (authorization) {
        const role: Role = getRole(authorization);

        if (window.localStorage.getItem('role') !== role && role !== Role.ADMIN) {
          navigate(authRoutes.accountSwitcher.getUrl());
        }
      }
    };

    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  });

  if (!data) {
    return isLoading ? <SplashScreen /> : <NoResults headline="Something went wrong" tagline="Please try again later." />;
  }

  const role = getRole(authorization);

  window.localStorage.setItem('role', role);

  return (
    <QueryHandler data={data} error={!!error} isLoading={isLoading} loadingComponent={<SplashScreen />}>
      {(results): ReactElement => {
        const accountPermissions = results.account?.permissions ? results.account?.permissions : {};
        const globalPermissions =
          role === Role.AGENT_FORWARDER
            ? results.legacyGlobalPermissions
            : merge(results?.legacyGlobalPermissions, results?.globalPermissions);

        return (
          <RoleContext.Provider value={role}>
            <GlobalPermissionsContextProvider value={globalPermissions}>
              <AccountPermissionsContextProvider value={accountPermissions}>{children}</AccountPermissionsContextProvider>
            </GlobalPermissionsContextProvider>
          </RoleContext.Provider>
        );
      }}
    </QueryHandler>
  );
};

export default RoleProvider;
