import { get } from 'lodash';
import type { FC } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Switch } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveAccountConnectionMutation, useCreateAccountConnectionMutation } from '../../graphql';
import { AccountConnectionTypeEnum } from '../../types';

interface Props {
  accountId: string;
  hasBusinessUnitsThatSupplyEachOther: boolean;
}

const InternalSupplyDetails: FC<Props> = ({ accountId, hasBusinessUnitsThatSupplyEachOther }) => {
  const { addError, addSuccess } = useNotification();

  const [createConnection, { loading: createConnectionLoading }] = useCreateAccountConnectionMutation();
  const [archiveConnection, { loading: archiveConnectionLoading }] = useArchiveAccountConnectionMutation();

  const handleSubmit = async (enabled: boolean) => {
    const mutation = enabled ? createConnection : archiveConnection;

    await performMutation({
      mutationFn: () =>
        mutation({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountDetails', 'getAccountConnections'],
          variables: {
            input: {
              connectionType: AccountConnectionTypeEnum.SUPPLIER,
              sourceAccountId: accountId,
              targetAccountId: accountId
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Intra-company trade could not be updated.'));
      },
      onSuccess: () => {
        addSuccess(`Intra-company trade ${enabled ? 'enabled' : 'disabled'}.`);
      }
    });
  };

  return (
    <EditableContentContainer heading="Intra-company trade">
      <Switch
        className="col-span-12"
        isChecked={hasBusinessUnitsThatSupplyEachOther}
        isDisabled={createConnectionLoading || archiveConnectionLoading}
        label="Enable intra-company trade"
        labelHint="Allow the account to purchase from and supply to its own business units."
        onChange={handleSubmit}
      />
    </EditableContentContainer>
  );
};

export default InternalSupplyDetails;
