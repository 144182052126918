import a from 'indefinite';
import { lowerCase } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import AccountContactsSelect from '@zen/Accounts/components/forms/AccountContactsSelect';
import { Button, Card, IconButton, InlineEmptyState, TextLink } from '@zen/DesignSystem';
import NetworksContactInput from '@zen/Networks/NetworksContactPicker/NetworksContactInput';
import type { AssignmentTypeValue, INetworksOrgLoc, NetworksOrgLoc, OrganisationLocation } from '@zen/Networks/types';
import { AssignmentTargetTypeEnum } from '@zen/Networks/types';
import type { IconType } from '@zen/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import NetworkContactDetails from './NetworkContactDetails';
import type { TradeParty } from './NetworkContactDetails/types';

interface Props {
  accountUuid?: string;
  assignmentType?: AssignmentTypeValue;
  className?: string;
  domainName?: AssignmentTargetTypeEnum;
  isClearable?: boolean;
  isEditable: boolean;
  label?: string;
  name: string;
  onChange?: (item: Nullable<OrganisationLocation>) => void;
  value: Optional<OrganisationLocation>;
}

const NetworkContactItem: FC<Props> = (props) => {
  const {
    accountUuid,
    assignmentType,
    className,
    domainName = AssignmentTargetTypeEnum.BOOKING,
    isEditable,
    name,
    onChange,
    value,
    label,
    isClearable
  } = props;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const title: string = label || removeSpecialCharactersAndUpperFirst(name);
  const lowercasedName: string = lowerCase(name);
  const canShowEditButton: boolean = isEditable && !!accountUuid && !!value && !isEditMode;
  const isAccountContactSelectVisible: boolean = name !== 'originAgent';

  const rightIcon: Undefinable<IconType> = canShowEditButton ? (
    <IconButton icon="zicon-edit" onClick={() => setIsEditMode(true)} size="medium" title="Edit" variant="ghost" />
  ) : undefined;

  const handleChange = (selected: Nullable<NetworksOrgLoc>): void => {
    onChange?.(selected);
    setIsEditMode(false);
  };

  const renderInput = (): ReactNode => {
    if (!accountUuid || (accountUuid && !assignmentType)) {
      return null;
    }

    return (
      <div className="mt-4">
        {isAccountContactSelectVisible && (
          <AccountContactsSelect
            accountUuid={accountUuid}
            assignmentType={assignmentType as AssignmentTypeValue}
            isClearable={isClearable}
            name={lowercasedName}
            onChange={handleChange}
            value={value as INetworksOrgLoc}
          />
        )}
        {!isAccountContactSelectVisible && (
          <NetworksContactInput
            accountUuid={accountUuid}
            assignmentType={assignmentType as AssignmentTypeValue}
            domainName={domainName}
            formTitle={`Add a new ${lowercasedName}`}
            isClearable={isClearable}
            name={lowercasedName}
            onChange={handleChange}
            value={value as INetworksOrgLoc}
          />
        )}
        <Button className="mt-4" onClick={() => setIsEditMode(false)} variant="secondary">
          Cancel
        </Button>
      </div>
    );
  };

  const renderEmptyState = (): ReactNode => {
    if (!isEditable) {
      return <InlineEmptyState>No {lowercasedName} is assigned.</InlineEmptyState>;
    }

    return (
      <InlineEmptyState>
        Please{' '}
        <TextLink isStandalone={false} onClick={() => setIsEditMode(true)}>
          assign {a(lowercasedName)}.
        </TextLink>
      </InlineEmptyState>
    );
  };

  const renderData = (): ReactNode => {
    if (!value) {
      return renderEmptyState();
    }

    return <NetworkContactDetails tradeParty={value as TradeParty} />;
  };

  return (
    <Card className={className} rightIcon={rightIcon} title={title}>
      {isEditMode ? renderInput() : renderData()}
    </Card>
  );
};

export default NetworkContactItem;
