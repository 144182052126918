import type { FC } from 'react';
import { useEffect } from 'react';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import useAccount from '@zen/utils/hooks/useAccount';

interface Props {
  accountIdForUrl: string;
}

const AutomaticAccountSwitcher: FC<Props> = ({ accountIdForUrl }) => {
  const { accountUuid, userProfile } = useAccount();
  const { switchToAccount } = useSwitchAccount();

  useEffect(() => {
    const accountIdMismatch: boolean = accountIdForUrl !== accountUuid;
    const shouldSwitchAccount: boolean = userProfile.isAdmin && accountIdMismatch;

    if (shouldSwitchAccount) {
      switchToAccount(accountIdForUrl);
    }
  });

  return null;
};

export default AutomaticAccountSwitcher;
