import type { FC, ReactNode } from 'react';

import { useSearchableAccountOptions } from '@zen/Accounts/hooks/useSearchableAccountOptions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { Option } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';
import { accountsRoutes } from '@zen/Routes';
import type { Nullable, Optional } from '@zen/utils/typescript';

import FormSelect from '../FormSelect';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  noMargin?: boolean;
  onChange?: (value: Nullable<string>, options: Option<string>[]) => void;
  value?: Optional<string>;
  variant?: 'inline' | 'default';
}

const FormAccountSelect: FC<Props> = (props) => {
  const { check } = useGlobalPermissions();
  const canCreateAccounts: boolean = check('platform.canCreateAccounts');

  const { options, loading, onSearch } = useSearchableAccountOptions({ selectedValues: props.value ? [props.value] : undefined });

  const renderFooter = canCreateAccounts
    ? (): ReactNode => {
        return <TextLink linkTo={{ pathname: accountsRoutes.accountsCreate.getUrl() }}>Create new account</TextLink>;
      }
    : undefined;

  const handleChange = (value: Nullable<string>) => {
    props.onChange?.(value, options);
  };

  return (
    <FormSelect
      {...props}
      dropdownFooterRenderer={renderFooter}
      isLoading={loading}
      labelPlacement="left"
      onChange={handleChange}
      onInputChange={onSearch}
      options={options}
      placeholder="Search for an account..."
      renderMenuInPortal={true}
    />
  );
};

export default FormAccountSelect;
export type { Props as FormAccountSelectProps };
